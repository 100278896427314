.DialogOverlay {
  background-color: #d9d9d9c0;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1001;
}

.DialogContent {
  background-color: var(--black);
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 600px;
  max-height: 85vh;
  padding: 40px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: scroll;
  @media (max-width: 479px) {
    padding: 40px 20px;
  }
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  text-align: center;
  font-family: var(--ff-500);
  font-size: 24px;
  letter-spacing: -0.7px;
  color: var(--white);
  text-transform: uppercase;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--white);
  font-size: 15px;
  font-size: 14px;
  text-align: left;
  line-height: 1;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  cursor: pointer;
}

.Button.green {
  font-size: 16px;
  color: var(--orange);
  font-family: var(--ff-500);
}

.Button.green:disabled {
  color: var(--line);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  background-color: var(--orange);
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
  font-family: var(--ff-700);
  cursor: pointer;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  color: var(--white);
  width: 120px;
  text-align: left;
  line-height: 1;
}

.Label span {
  color: var(--orange);
}

.Input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 16px;
  line-height: 1;
  color: var(--white);
  height: 35px;
  border-bottom: 1px solid var(--line);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
