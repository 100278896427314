/* reset */
button,
h3 {
  all: unset;
}

.AccordionRoot {
  border-radius: 6px;
  background-color: var(--mauve-6);
  box-shadow: 0 2px 10px var(--black-a4);
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
}

.AccordionItem:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 2px var(--mauve-12);
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: inherit;
  background-color: transparent;
  font-size: 24px;
  padding: 10px 0;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  letter-spacing: -0.7px;
  border-bottom: 1px solid var(--line);
  text-align: left;
  &:hover {
    color: var(--orange);
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    height: 35px;
  }
  @media (max-width: 424px) {
    font-size: 18px;
  }
}

.AccordionContent {
  overflow: hidden;
  color: var(--grey);
}
.AccordionContent[data-state="open"] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  border-bottom: 1px solid var(--line);
}
.AccordionContent[data-state="closed"] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  border-bottom: none;
}

.AccordionContentText {
  padding: 15px 0;
  color: var(--grey);
  text-align: left;
  letter-spacing: -0.03rem;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    padding: 10px 0;
  }
}

.AccordionChevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionTrigger[data-state="open"] {
  border-bottom: none;
}

.AccordionTrigger[data-state="open"] > .AccordionChevron {
  transform: rotate(270deg);
}
