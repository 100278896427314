/* reset */
button {
  all: unset;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: var(--black);
  cursor: pointer;
}

.SelectContent {
  overflow: hidden;
  z-index: 10002;
  background-color: var(--orange);
  border-radius: 6px;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 16px;
  line-height: 1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--black);
}

/* .SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
} */

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: var(--black);
  cursor: default;
}
