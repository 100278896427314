@font-face {
  font-family: "Ruberoid";
  src: url("./assets/fonts/Ruberoid-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Ruberoid thin";
  src: url("./assets/fonts/Ruberoid-LightOblique.woff2") format("woff2");
}
@font-face {
  font-family: "Ruberoid medium";
  src: url("./assets/fonts/Ruberoid-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Ruberoid semibold";
  src: url("./assets/fonts/Ruberoid-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Ruberoid bold";
  src: url("./assets/fonts/Ruberoid-Bold.woff2") format("woff2");
}

:root {
  --ff: "Ruberoid";
  --ff-300: "Ruberoid thin";
  --ff-500: "Ruberoid medium";
  --ff-600: "Ruberoid semibold";
  --ff-700: "Ruberoid bold";

  --black: #0c0c0c;
  --orange: #eb4526;
  --white: #d9d9d9;
  --grey: rgba(217, 217, 217, 0.5);
  --line: rgba(217, 217, 217, 0.2);
}

ul,
li {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

* {
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--white);
  letter-spacing: -0.5px;
  border: none;
  font-weight: unset;
}

input {
  border: none;
  background-color: inherit;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: initial;
  }
}

body {
  margin: 0;
  font-family: var(--ff), -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1e1e;
  color: var(--white);
}

#root {
  width: 100%;
  overflow: hidden;
}
