.App {
  position: relative;
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.App main {
  overflow: hidden;
}

.projectTitle {
  display: flex;
  align-items: center;
  color: var(--orange);
  gap: 40px;

  @media (max-width: 1023px) {
    gap: 20px;
  }
  & h3 {
    color: var(--orange);
    font-family: var(--ff-600);
    font-size: 96px;
    letter-spacing: -2.8px;
    @media (max-width: 1023px) {
      font-size: 70px;
      letter-spacing: -2px;
    }
  }
  & h4 {
    color: var(--orange);
    font-family: var(--ff-600);
    font-size: 32px;
    letter-spacing: -0.95px;
    @media (max-width: 1023px) {
      font-size: 28px;
      letter-spacing: -0.65px;
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
